import React, { useEffect } from "react";

import enTranslations from "@shopify/polaris/locales/en.json";
import { Provider, useNavigate } from "@shopify/app-bridge-react";
import { AppProvider, Page } from "@shopify/polaris";

function SubRedirect({ resourceType, resourceId }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (resourceId) {
      navigate({
        name: resourceType,
        resource: {
          id: resourceId,
        },
      });
    } else {
      navigate({
        name: resourceType,
      });
    }
  }, []);

  return null;
}

export default function Redirect(props) {
  const { apiKey, host, resourceType, resourceId } = props;
  const config = { apiKey, host };

  console.log({ config });

  return (
    <AppProvider i18n={enTranslations}>
      <Provider config={config}>
        <SubRedirect resourceType={resourceType} resourceId={resourceId} />
      </Provider>
    </AppProvider>
  );
}
