import React, { useState, useCallback } from "react";

import enTranslations from "@shopify/polaris/locales/en.json";
import { Provider } from "@shopify/app-bridge-react";
import {
  AppProvider,
  Page,
  Layout,
  Button,
  Card,
  TextField,
  FormLayout,
  Stack,
} from "@shopify/polaris";

export default function StickersPage(props) {
  const { apiKey, host } = props;
  const config = { apiKey, host };

  const [tag, setTag] = useState("");

  const [email, setEmail] = useState("operations@nona-source.com");

  const disabled = tag.length === 0 || email.length === 0;

  const [loadingStickers, setLoadingStickers] = useState(false);

  const handleSendStickers = useCallback(() => {
    setLoadingStickers(true);
    fetch("/api/products/file", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ tag, email, template: "stickers" }),
    }).then(() => setLoadingStickers(false));
  }, [tag, email]);

  const [loadingSlips, setLoadingSlips] = useState(false);

  const handleSendSlips = useCallback(() => {
    setLoadingSlips(true);
    fetch("/api/products/file", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ tag, email, template: "slips" }),
    }).then(() => setLoadingSlips(false));
  }, [tag, email]);

  const [loadingNewSlips, setLoadingNewSlips] = useState(false);

  const handleSendNewSlips = useCallback(() => {
    setLoadingNewSlips(true);
    fetch("/api/products/file", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ tag, email, template: "newSlips" }),
    }).then(() => setLoadingNewSlips(false));
  }, [tag, email]);

  const [loadingSwatchcard, setLoadingSwatchcard] = useState(false);

  const handleSendSwatchcard = useCallback(() => {
    setLoadingSlips(true);
    fetch("/api/products/file", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ tag, email, template: "swatchcard" }),
    }).then(() => setLoadingSlips(false));
  }, [tag, email]);

  return (
    <AppProvider i18n={enTranslations}>
      <Provider config={config}>
        <Page narrowWidth singleColumn title="Print files">
          <Layout>
            <Layout.Section>
              <Card title="Send file to email address">
                <Card.Section>
                  <FormLayout>
                    <TextField
                      label="Enter a tag"
                      value={tag}
                      onChange={(value, id) => setTag(value)}
                      autoComplete="off"
                    />
                    <TextField
                      label="Email address"
                      value={email}
                      onChange={(value, id) => setEmail(value)}
                      placeholder="jane.doe@email.com"
                      autoComplete="off"
                    />
                    <Stack>
                      <Button
                        disabled={disabled}
                        loading={loadingStickers}
                        onClick={handleSendStickers}
                      >
                        Send stickers
                      </Button>
                      <Button
                        disabled={disabled}
                        loading={loadingSlips}
                        onClick={handleSendSlips}
                      >
                        Send showroom slips OLD
                      </Button>
                      <Button
                        disabled={disabled}
                        loading={loadingNewSlips}
                        onClick={handleSendNewSlips}
                      >
                        Send showroom slips NEW
                      </Button>
                      <Button
                        disabled={disabled}
                        loading={loadingSwatchcard}
                        onClick={handleSendSwatchcard}
                      >
                        Send swatchcard
                      </Button>
                    </Stack>
                  </FormLayout>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </Provider>
    </AppProvider>
  );
}
