import React, { useState, useCallback } from "react";
import { Card, Button, List, Link } from "@shopify/polaris";

import PickupPrice from "./PickupPrice";

export default function CarrierServices(props) {
  const [services, setServices] = useState(window.carrierServices);

  const registerService = useCallback(() => {
    fetch("/shipping", {
      method: "POST",
    })
      .then((response) => response.json())
      .then((newService) => setServices([...services, newService]));
  }, [services]);

  const removeService = useCallback(
    (serviceId) => {
      fetch("/shipping/" + serviceId, {
        method: "DELETE",
      }).then(() => setServices(services.filter((service) => service.id != serviceId)));
    },
    [services],
  );

  const customService = services.find((service) => {
    return service.name == "Nona Source - Custom shipping - dev";
  });

  return (
    <Card title="Carrier Services">
      {services.length > 0 && (
        <Card.Section>
          <List type="bullet">
            {services.map((service) => (
              <List.Item key={service.id}>
                {service.name}&nbsp;
                <Link onClick={() => removeService(service.id)}>Remove</Link>
              </List.Item>
            ))}
          </List>
        </Card.Section>
      )}

      {!customService && (
        <Card.Section>
          <Button onClick={registerService}>Register custom services</Button>
        </Card.Section>
      )}
    </Card>
  );
}
