import React, { useState, useCallback } from "react";
import {
  Card,
  Stack,
  TextField,
  Button,
} from "@shopify/polaris";

export default function PickupPrice(props) {
  const [pickupPrice, setPickupPrice] = useState(window.pickupPrice);

  const [loading, setLoading] = useState(false);

  const updatePickupPrice = useCallback((value) => {
    setLoading(true);
    fetch("/shop", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pickup_price: pickupPrice,
      }),
    }).then(() => setLoading(false));
  }, [pickupPrice]);

  return (
    <Card.Section>
      <Stack alignment="trailing">
        <TextField
          label="Pick-up price"
          type="number"
          value={pickupPrice}
          onChange={setPickupPrice}
        />
        <Button primary onClick={updatePickupPrice} loading={loading}>
          Update
        </Button>
      </Stack>
    </Card.Section>
  );
}
