import React from "react";

import enTranslations from "@shopify/polaris/locales/en.json";
import { Provider } from "@shopify/app-bridge-react";
import { AppProvider, Page, Layout, Banner } from "@shopify/polaris";

import MediaUpload from "./MediaUpload";

export default function UploadPage(props) {

  const { apiKey, host } = props;
  const config = { apiKey, host };

  return (
    <AppProvider i18n={enTranslations}>
      <Provider config={config}>
        <Page narrowWidth singleColumn title="Upload all product medias">
          <Layout>
            <Layout.Section>
              <Banner title="Read carefully" status="info">
                <p>
                  We recommend you upload photos by batch of 400 max, depending
                  on your bandwith.
                </p>
                <p>
                  Warning: if you don't check the option, all previously uploaded photos and videos will be deleted for each product included in your upload.
                </p>
              </Banner>
            </Layout.Section>
            <Layout.Section>
              <MediaUpload />
            </Layout.Section>
          </Layout>
        </Page>
      </Provider>
    </AppProvider>
  );
}