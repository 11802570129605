import React, { useState } from "react";

import { Button, Card, TextField, FormLayout, Stack, Badge } from "@shopify/polaris";
import axios from "axios";

export default function SyncKlaviyoFeed() {
  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const disabled = tag.length === 0;

  const handleSyncFeed = async () => {
    setLoading(true);
    const res = await axios.post("/api/products/sync_klaviyo", { tag });
    if (res.data?.message) {
      setMessage(res.data.message);
      setTimeout(() => setMessage(null), 5000);
    }
    setLoading(false);
  };

  return (
    <Card title="Sync Klaviyo feed">
      <Card.Section>
        <FormLayout>
          <TextField
            label="Enter a product tag"
            value={tag}
            onChange={(value, id) => setTag(value)}
            autoComplete="off"
          />
          <Stack>
            <Button disabled={disabled} loading={loading} onClick={handleSyncFeed}>
              Sync Klaviyo feed
            </Button>
            {message?.length > 0 && <Badge status="success">{message}</Badge>}
          </Stack>
        </FormLayout>
      </Card.Section>
    </Card>
  );
}
