import _ from "lodash";
import React from "react";
import {
  DropZone,
  PageActions,
  ResourceList,
  ResourceItem,
  Thumbnail,
  TextStyle,
  Stack,
  Spinner,
  Badge,
  Card,
  Checkbox,
  Layout,
} from "@shopify/polaris";

const validImageTypes = ["image/jpeg", "image/png"];
const acceptedFileTypes = [...validImageTypes, "video/mp4"];
// const acceptedFileTypes = [...validImageTypes];

export default class MediaUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keepExistingMedia: false,
      isUploading: false,
      files: [],
    };
  }

  handleCheckbox = (newChecked) => {
    this.setState({ keepExistingMedia: newChecked });
  };

  handleDropZoneDrop = (_droppedFiles, acceptedFiles, refusedFiles) => {
    this.setState({
      canUpload: acceptedFiles.length > 0,
      files: acceptedFiles
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        // .slice(0, 100)
        .map((file, index) => ({
          productHandle: file.name.split("_")[0],
          file,
          index,
          loading: false,
          success: false,
          error: false,
        })),
    });
  };

  setFileStatus = (handle, loading, success, error) => {
    this.setState((prevState) => ({
      files: prevState.files.map((file) => ({
        ...file,
        loading: file.productHandle == handle ? loading : file.loading,
        success: file.productHandle == handle ? success : file.success,
        error: file.productHandle == handle ? error : file.error,
      })),
    }));
  };

  uploadFiles = () => {
    // this.setState({ isUploading: true });
    const groupedFiles = _.groupBy(this.state.files, "productHandle");
    this.uploadProductFiles(0, groupedFiles);
  };

  uploadProductFiles = (index, products) => {
    const handle = Object.keys(products)[index];
    const files = Object.values(products)[index];
    this.uploadMediaToProduct(handle, files, () => {
      this.uploadProductFiles(index + 1, products);
    });
  };

  uploadMediaToProduct(handle, files, callback) {
    this.setFileStatus(handle, true, false, false);

    let formData = new FormData();

    formData.append("handle", handle);
    formData.append("keep", this.state.keepExistingMedia);

    files.forEach((file) => {
      formData.append("files[]", file.file);
    });

    const request = new XMLHttpRequest();
    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        if (request.status === 200) {
          this.setFileStatus(handle, false, true, false);
        } else if (request.status == 404) {
          this.setFileStatus(handle, false, false, "Product not found");
        } else {
          this.setFileStatus(handle, false, false, "Upload failed");
        }
        callback();
      }
    };
    request.open("POST", "/upload");
    request.send(formData);
  }

  renderFileItem = (item) => {
    const { productHandle, file, loading, index, success, error } = item;
    const { name, type } = file;
    const source =
      validImageTypes.indexOf(type) >= 0
        ? window.URL.createObjectURL(file)
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304";
    const media = <Thumbnail size="small" alt={name} source={source} />;
    return (
      <ResourceItem id={name} media={media}>
        <Stack alignment="center">
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{name}</TextStyle>
            </h3>
            <div>
              Media {index + 1} on {this.state.files.length} - Product{" "}
              {productHandle}
            </div>
          </Stack.Item>
          <Stack.Item>
            {loading && (
              <Spinner
                accessibilityLabel="Small spinner example"
                size="small"
                color="teal"
              />
            )}
            {success && <Badge status="success">Media uploaded</Badge>}
            {error && <Badge status="warning">{error}</Badge>}
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  render() {
    const { files, isUploading, keepExistingMedia } = this.state;

    const fileUpload = !files.length && (
      <DropZone.FileUpload actionTitle="Select media files" />
    );
    const uploadedFiles = files.length > 0 && (
      <ResourceList
        resourceName={{ singular: "file", plural: "files" }}
        items={files}
        renderItem={this.renderFileItem}
      />
    );
    return (
      <Layout>
        <Layout.Section>
          <DropZone
            onDrop={this.handleDropZoneDrop}
            accept={acceptedFileTypes.join(",")}
            disabled={isUploading}
          >
            {uploadedFiles}
            {fileUpload}
          </DropZone>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <Card.Section>
              <Checkbox
                label="Keep existing photos and videos on these products"
                checked={keepExistingMedia}
                onChange={this.handleCheckbox}
              />
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: "Upload files",
              disabled: isUploading || files.length == 0,
              onAction: this.uploadFiles,
            }}
          />
        </Layout.Section>
      </Layout>
    );
  }
}
