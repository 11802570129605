import React, { useEffect, useState } from "react";

export default function ConfirmFormSelect(props) {
  const { label, name, options, value, setValue, required = false } = props;

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (localValue === "other") return;
    setValue(localValue);
  }, [localValue]);

  return (
    <div className="flex items-stretch space-x-2">
      <select
        className="w-full outline-none p-1"
        name={name}
        onChange={(evt) => setLocalValue(evt.target.value)}
        required={required}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={!option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {localValue === "other" && (
        <input
          className="w-full outline-none p-1"
          name={name}
          value={value || ""}
          onChange={(evt) => setValue(evt.target.value)}
          required={required}
          placeholder={label}
          autoFocus
        />
      )}
    </div>
  );
}
