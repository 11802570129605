import React, { useState, useCallback } from "react";

import enTranslations from "@shopify/polaris/locales/en.json";
import { Provider } from "@shopify/app-bridge-react";
import { AppProvider, Page, Layout, Button, Card, TextField, FormLayout, Stack } from "@shopify/polaris";
import SendStockAlerts from "./stock_release/SendStockAlerts";
import SyncKlaviyoFeed from "./stock_release/SyncKlaviyoFeed";

export default function StockReleasePage(props) {
  const { apiKey, host } = props;
  const config = { apiKey, host };

  return (
    <AppProvider i18n={enTranslations}>
      <Provider config={config}>
        <Page narrowWidth singleColumn title="New stock release">
          <Layout>
            <Layout.Section>
              <SendStockAlerts />
            </Layout.Section>
            <Layout.Section>
              <SyncKlaviyoFeed />
            </Layout.Section>
          </Layout>
        </Page>
      </Provider>
    </AppProvider>
  );
}
