import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { QrReader } from "react-qr-reader";
import { CheckCircleIcon, PencilIcon, XIcon } from "@heroicons/react/solid";
import { debounce, delay } from "lodash";
import ConfirmForm from "./ConfirmForm";

export default function SampleScanApp(props) {
  const [selectedColors, setSelectedColors] = useState([]);
  // const [selectedColors, setSelectedColors] = useState([{
  //   color_id: "16468",
  //   product: "Water Repellent Taffetas",
  //   handle: "T22O01214",
  //   color: "Tillandsia Purple"
  // }, {
  //   color_id: "16468",
  //   product: "Water Repellent Taffetas",
  //   handle: "T22O01214",
  //   color: "Tillandsia Purple"
  // }]);
  const [showScanner, setShowScanner] = useState(false);

  const selectionZoneRef = useRef(null);

  // Product form

  const [productForm, setProductForm] = useState(false);
  const [colorQuery, setColorQuery] = useState("");
  const [colorResults, setColorResults] = useState([]);

  useEffect(() => handleSearchColor(colorQuery), [colorQuery]);

  // Client form

  const [clientForm, setClientForm] = useState(false);
  const [clientManualForm, setClientManualForm] = useState(false);
  const [clientQuery, setClientQuery] = useState("");
  const [clientResults, setClientResults] = useState([]);
  const [client, setClient] = useState();

  useEffect(() => handleSearchClients(clientQuery), [clientQuery]);

  // Confirm form

  const [confirmForm, setConfirmForm] = useState(false);
  const showrooms = ["Paris Showroom", "Special Request", "Premiere Vision"];
  const [showroom, setShowroom] = useState(props?.showroom);

  // methods

  const handleSearchColor = useCallback(
    debounce((colorQuery) => {
      if (!colorQuery.length) {
        setSelectedColors([]);
      } else {
        axios.get(`/api/colors?query=${colorQuery}`).then((res) => setColorResults(res.data));
      }
    }, 500),
    [],
  );

  // const searchClientsUrl = showroom == "Premiere Vision" ? "/api/customers/hubspot_search" : "/api/customers";
  const searchClientsUrl = "/api/customers/hubspot_search";

  const handleSearchClients = useCallback(
    debounce((clientQuery) => {
      if (!clientQuery.length) {
        setClientResults([]);
      } else {
        axios.get(`${searchClientsUrl}?query=${clientQuery}`).then((res) => {
          console.log(res.data);
          setClientResults(res.data);
        });
      }
    }, 500),
    [],
  );

  const handleScanData = (data) => {
    if (!showScanner) return;
    setShowScanner(false);

    // const handle = data.match(/products\/(.+)\?/) ? data.match(/products\/(.+)\?/)[1] : null;
    const handle = data.match(/products\/(.+)\?/)?.[1];
    const colorName = data.match(/\?color\=(.+)/)?.[1];

    if (!handle || !colorName) {
      window.alert("Invalid QR code");
      return;
    }

    searchProduct(handle, colorName);
  };

  const searchProduct = (handle, colorName) => {
    axios
      .get(`/api/products/${handle}`)
      .then((res) => {
        const product = res.data;
        const color = product.colors.find((c) => c.title.split(" ").join("") == colorName);
        if (product && color) {
          addColor({
            color_id: color.id,
            handle: color.nona_id,
            color: color.title,
          });
        } else {
          window.alert("Product not found.");
        }
      })
      .catch((err) => {
        window.alert("Product not found.");
      });
  };

  const addColor = (color) => {
    setSelectedColors((colors) => [...colors, color]);
    delay(() => (selectionZoneRef.current.scrollTop = selectionZoneRef.current.scrollHeight), 50);
  };

  const removeColor = (index) => {
    setSelectedColors((colors) => [...colors.slice(0, index), ...colors.slice(index + 1)]);
  };

  if (showScanner) {
    return (
      <div className="w-full max-w-md mx-auto flex flex-col justify-center space-y-8 py-8">
        <QrReader
          constraints={{
            facingMode: "environment",
          }}
          scanDelay={1000}
          onResult={(result, error) => {
            if (!!result) {
              handleScanData(result?.text);
            }

            if (!!error) {
              console.info(error);
            }
          }}
        />
        <button className="bg-indigo-400 rounded p-3 text-white font-bold" onClick={() => setShowScanner(false)}>
          Cancel
        </button>
      </div>
    );
  }

  if (!showroom) {
    return (
      <div className="max-w-2xl h-screen mx-auto p-6 flex flex-col justify-center space-y-4 pb-48">
        <h2 className="text-3xl font-bold mb-8">Select showroom</h2>
        {showrooms.map((sh) => (
          <div
            key={sh}
            className={`flex items-center justify-between border mb-4 p-4 rounded-lg select-none cursor-pointer font-bold hover:bg-indigo-600 hover:text-white transition-colors ${
              showroom == sh ? "bg-indigo-600 text-white" : "bg-white"
            }`}
            onClick={() => setShowroom(sh)}
          >
            <div>{sh}</div>
            {showroom == sh && <CheckCircleIcon className="w-6 h-6 text-white" />}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="h-screen max-h-screen max-w-2xl mx-auto p-6 flex flex-col justify-center space-y-4">
      <div
        className="flex-grow overflow-auto flex flex-col items-stretch space-y-4 bg-white rounded-lg p-4 border"
        ref={selectionZoneRef}
      >
        {selectedColors.length == 0 && <div>No codes scanned yet.</div>}
        {selectedColors.map((color, index) => (
          <div className="flex items-center justify-between p-4 border rounded" key={color.handle}>
            <div>
              {/* <div className="mb-1">{color.product}</div> */}
              <div>
                {color.handle} - {color.color} - {color.available_length}
                {color.unit} - {color.price}€/{color.unit}
              </div>
            </div>
            <button className="p-2" onClick={() => removeColor(index)}>
              <XIcon className="w-6 h-6" />
            </button>
          </div>
        ))}
      </div>
      {selectedColors.length > 0 && <div className="px-4 text-sm">{selectedColors.length} product(s) selected</div>}
      <div className="flex-shrink-0 flex flex-col space-y-4">
        {showroom && (
          <div className="flex items-end justify-between p-4 border rounded bg-white">
            <div>
              <div className="text-sm text-neutral-500 pb-0.5">Showroom</div>
              {showroom}
            </div>
            <button className="p-2" onClick={() => setShowroom(null)}>
              <PencilIcon className="w-6 h-6" />
            </button>
          </div>
        )}
        {!client && (
          <button className="bg-amber-400 rounded p-4 text-white font-bold" onClick={() => setClientForm(true)}>
            Search client
          </button>
        )}
        {client && (
          <div className="flex items-end justify-between p-4 border rounded bg-white">
            <div>
              <div className="text-sm text-neutral-500 pb-0.5">Client</div>
              {client?.name} - {client?.email}
              {client.company ? ` - ${client.company}` : ""}
            </div>
            <button className="p-2" onClick={() => setClientForm(true)}>
              <PencilIcon className="w-6 h-6" />
            </button>
          </div>
        )}
        {selectedColors.length > 0 && client && (
          <button className="bg-green-600 rounded p-4 text-white font-bold" onClick={() => setConfirmForm(true)}>
            Confirm order
          </button>
        )}
        <button className="bg-indigo-400 rounded p-4 text-white font-bold" onClick={() => setProductForm(true)}>
          Search product
        </button>
        <button className="bg-indigo-700 rounded p-4 text-white font-bold" onClick={() => setShowScanner(true)}>
          Scan QR code
        </button>
      </div>
      {/* Search client */}
      {clientForm && (
        <div
          className="fixed inset-0 bg-white bg-opacity-90 flex items-center justify-center"
          onClick={() => {
            setClientForm(false);
            setClientManualForm(false);
          }}
        >
          {!clientManualForm ? (
            <div
              className="max-w-xl w-full bg-white rounded-lg border shadow p-4"
              onClick={(evt) => evt.stopPropagation()}
            >
              <h2 className="text-3xl font-bold mb-8">Search client</h2>
              <input
                className="block border py-1 px-2 w-full rounded mb-4 text-2xl outline-none"
                type="search"
                placeholder="Name or email"
                required
                autoFocus
                value={clientQuery}
                onChange={(evt) => setClientQuery(evt.target.value)}
              />
              {clientResults.length > 0 && <div className="text-xl text-neutral-500 mb-4">Results</div>}
              {clientResults.map((result, index) => (
                <button
                  className="w-full text-left py-2 px-3 border mb-2 rounded-lg hover:bg-neutral-50"
                  key={index}
                  onClick={() => {
                    setClient({
                      name: `${result.first_name} ${result.last_name}`,
                      email: result.email,
                      company: result.company,
                    });
                    setClientForm(false);
                  }}
                >
                  {result.first_name} {result.last_name} - {result.email}
                  {result.company ? ` - ${result.company}` : ""}
                </button>
              ))}
              {showroom == "Special Request" && (
                <button
                  type="submit"
                  className="bg-orange-400 rounded p-3 text-white font-bold w-full mt-4"
                  onClick={() => setClientManualForm(true)}
                >
                  Enter client manually
                </button>
              )}
              <button
                type="submit"
                className="bg-neutral-400 rounded p-3 text-white font-bold w-full mt-4"
                onClick={() => {
                  setClientForm(false);
                  setClientManualForm(false);
                }}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div
              className="max-w-xl w-full bg-white rounded-lg border shadow p-4"
              onClick={(evt) => evt.stopPropagation()}
            >
              <h2 className="text-3xl font-bold mb-8">Enter client manually</h2>
              <input
                className="block border py-1 px-2 w-full rounded mb-4 text-2xl outline-none"
                name="name"
                type="text"
                placeholder="Name"
                required
                value={client?.name || ""}
                onChange={(evt) => setClient({ ...client, name: evt.target.value })}
              />
              <input
                className="block border py-1 px-2 w-full rounded mb-4 text-2xl outline-none"
                name="email"
                type="email"
                placeholder="Email"
                required
                value={client?.email || ""}
                onChange={(evt) => setClient({ ...client, email: evt.target.value })}
              />
              <button
                type="submit"
                className="bg-green-700 rounded p-3 text-white font-bold w-full mt-4"
                onClick={() => {
                  setClientForm(false);
                  setClientManualForm(false);
                }}
              >
                Confirm
              </button>
              <button
                type="submit"
                className="bg-neutral-400 rounded p-3 text-white font-bold w-full mt-4"
                onClick={() => {
                  setClientForm(false);
                  setClientManualForm(false);
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      )}
      {/* Search color */}
      {productForm && (
        <div
          className="fixed inset-0 bg-white bg-opacity-90 flex items-center justify-center"
          onClick={() => {
            setColorQuery("");
            setProductForm(false);
          }}
        >
          <div
            className="max-w-xl w-full bg-white rounded-lg border shadow p-4"
            onClick={(evt) => evt.stopPropagation()}
          >
            <h2 className="text-3xl font-bold mb-8">Search product</h2>
            <input
              className="block border py-1 px-2 w-full rounded mb-4 text-2xl outline-none"
              type="search"
              required
              autoFocus
              value={colorQuery}
              onChange={(evt) => setColorQuery(evt.target.value)}
            />
            {colorResults.length > 0 && <div className="text-xl text-neutral-500 mb-4">Results</div>}
            {colorResults.map((result, index) => (
              <div
                className="py-2 px-3 border mb-2 rounded-lg cursor-pointer"
                key={index}
                onClick={() => {
                  addColor(result);
                  setProductForm(false);
                }}
              >
                {result.handle} - {result.color} - {result.available_length}
                {result.unit} - {result.price}€/{result.unit}
              </div>
            ))}
          </div>
        </div>
      )}
      {/* Confirm */}
      {confirmForm && (
        <ConfirmForm
          client={client}
          colors={selectedColors}
          showroom={showroom}
          onClose={() => setConfirmForm(false)}
        />
      )}
    </div>
  );
}
