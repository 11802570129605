import React, { useState } from "react";
import ConfirmFormSelect from "./ConfirmFormSelect";

const countries = [
  { label: "France", value: "FR" },
  { label: "United Kingdom", value: "GB" },
  { label: "", value: "" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Belgium", value: "BE" },
  { label: "Bulgaria", value: "BG" },
  { label: "China", value: "CN" },
  { label: "Croatia", value: "HR" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Estonia", value: "EE" },
  { label: "Finland", value: "FI" },
  { label: "Germany", value: "DE" },
  { label: "Greece", value: "GR" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "Ireland", value: "IE" },
  { label: "Italy", value: "IT" },
  { label: "Japan", value: "JP" },
  { label: "Latvia", value: "LV" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Malta", value: "MT" },
  { label: "Morocco", value: "MA" },
  { label: "Netherlands", value: "NL" },
  { label: "Norway", value: "NO" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Romania", value: "RO" },
  { label: "Serbia", value: "RS" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "South Korea", value: "KR" },
  { label: "Spain", value: "ES" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CHE" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "United States", value: "US" },
  { label: "", value: "" },
  { label: "Other", value: "other" },
];

const phoneCodes = [
  "+33",
  "+44",
  "",
  "+1",
  "+30",
  "+31",
  "+32",
  "+34",
  "+36",
  "+39",
  "+40",
  "+41",
  "+43",
  "+45",
  "+46",
  "+47",
  "+48",
  "+49",
  "+61",
  "+81",
  "+82",
  "+86",
  "+90",
  "+212",
  "+216",
  "+351",
  "+352",
  "+353",
  "+354",
  "+356",
  "+357",
  "+358",
  "+359",
  "+370",
  "+371",
  "+372",
  "+381",
  "+385",
  "+386",
  "+420",
  "+421",
  "+852",
];

const shippingFields = [
  {
    name: "address1",
    label: "Address",
    required: true,
  },
  {
    name: "address2",
    label: "Add. details",
  },
  {
    name: "city",
    label: "City",
    required: true,
  },
  {
    name: "postal_code",
    label: "Postal code",
    required: true,
  },
  {
    name: "country",
    label: "Country",
    options: countries,
    required: true,
  },
  {
    name: "company",
    label: "Company",
    required: true,
  },
  {
    name: "phone",
    label: "Phone",
    required: true,
  },
];

export default function ConfirmForm(props) {
  const { onClose, client, showroom, colors } = props;

  const [comment, setComment] = useState("");

  const [createShipping, setCreateShipping] = useState(false);
  const [shippingDetails, setShippingDetails] = useState({
    company: client?.company,
  });

  const updateDetails = (key, value) => {
    setShippingDetails((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeDetails = (key) => (evt) => {
    evt.persist();
    updateDetails(key, evt.target.value);
  };

  const handleConfirm = (evt) => {
    evt.preventDefault();
    if (window.confirm("Confirm this order?")) {
      evt.target.submit();
    } else {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-white bg-opacity-90 flex items-center justify-center" onClick={onClose}>
      <div
        className="sm:max-w-xl w-full max-h-screen overflow-auto bg-white sm:rounded-lg sm:border sm:shadow p-4 pb-8"
        onClick={(evt) => evt.stopPropagation()}
      >
        <form action="/sample_orders" method="post" onSubmit={handleConfirm} className="space-y-4">
          <div className="p-4 border rounded bg-white">
            <div className="text-sm text-neutral-500 pb-0.5">Showroom</div>
            {showroom}
          </div>
          <div className="p-4 border rounded bg-white">
            <div className="text-sm text-neutral-500 pb-0.5">Client</div>
            {client?.name} - {client?.email}
            {client?.company ? ` - ${client?.company}` : ""}
          </div>
          <div className="p-4 border rounded bg-white">
            <div className="text-sm text-neutral-500 pb-0.5">Comment</div>
            <textarea
              className="border-none w-full outline-none p-1"
              name="sample_order[comment]"
              value={comment}
              onChange={(evt) => setComment(evt.target.value)}
            />
          </div>
          {showroom == "Premiere Vision" && (
            <div className="relative flex items-start">
              <div className="flex pt-1 items-center">
                <input
                  id="create_shipping"
                  name="create_shipping"
                  type="checkbox"
                  className="h-6 w-6 roundelg border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={createShipping}
                  onChange={() => setCreateShipping(!createShipping)}
                />
              </div>
              <div className="ml-3 mb-1">
                <label htmlFor="create_shipping" className="text-gray-900">
                  Add DHL shipping
                </label>
              </div>
            </div>
          )}
          {createShipping &&
            shippingFields.map((field) => (
              <div className="p-4 border rounded bg-white" key={field.name}>
                <label className="block text-sm text-neutral-500 pb-0.5" htmlFor={`shipping_details[${field.name}]`}>
                  {field.label} {field.required && "*"}
                </label>
                {field.options ? (
                  <ConfirmFormSelect
                    label={field.label}
                    name={`shipping_details[${field.name}]`}
                    options={field.options}
                    value={shippingDetails[field.name] || ""}
                    setValue={(value) => updateDetails(field.name, value)}
                    required={field.required}
                  />
                ) : (
                  <div className="flex items-center">
                    {field.name == "phone" && (
                      <select
                        className="w-[80px] outline-none p-1"
                        name={`shipping_details[phone_code]`}
                        onChange={handleChangeDetails("phone_code")}
                      >
                        {phoneCodes.map((phoneCode) => (
                          <option key={phoneCode} value={phoneCode} disabled={!phoneCode}>
                            {phoneCode}
                          </option>
                        ))}
                      </select>
                    )}
                    <input
                      id={`shipping_details[${field.name}]`}
                      className="w-full outline-none p-1"
                      name={`shipping_details[${field.name}]`}
                      value={shippingDetails[field.name] || ""}
                      onChange={handleChangeDetails(field.name)}
                      required={field.required}
                    />
                  </div>
                )}
              </div>
            ))}
          <input type="hidden" name="authenticity_token" value={document.querySelector("[name=csrf-token]").content} />
          <input name="sample_order[client_name]" value={client?.name} type="hidden" />
          <input name="sample_order[client_email]" value={client?.email} type="hidden" />
          <input name="sample_order[client_company]" value={client?.company} type="hidden" />
          <input name="sample_order[showroom]" value={showroom} type="hidden" />
          {colors.map((color) => (
            <input key={color.color_id} type="hidden" name="color_ids[]" value={color.color_id} />
          ))}
          <button
            type="submit"
            className="bg-indigo-700 disabled:opacity-50 rounded px-3 py-4 text-white font-bold w-full"
          >
            Confirm order
          </button>
          <button
            onClick={(evt) => {
              evt.preventDefault();
              onClose();
            }}
            className="bg-neutral-500 disabled:opacity-50 rounded px-3 py-4 text-white font-bold w-full sm:hidden"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
}
