import React from "react";

import { ResourceItem, TextStyle } from "@shopify/polaris";

export default function OrderItem(props) {
  const { id, legacyResourceId, name, customer } = props;

  const shortcutActions = [{ content: "View invoice", url: `/invoice?id=${legacyResourceId}` }];

  return (
    <ResourceItem
      id={id}
      url={`/invoice?id=${legacyResourceId}`}
      shortcutActions={shortcutActions}
      name={name}
    >
      <h3>
        <TextStyle variation="strong">Order {name}</TextStyle>
      </h3>
      {customer?.displayName || "No customer"}
    </ResourceItem>
  );
}