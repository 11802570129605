import React, { useState } from "react";
import axios from "axios";
import { QrReader } from "react-qr-reader";
import { CheckCircleIcon, PencilIcon } from "@heroicons/react/solid";
import _ from "lodash";

export default function InventoryScanApp() {
  const [color, setColor] = useState();
  const [options, setOptions] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const [showScanner, setShowScanner] = useState(false);
  const [showroomForm, setShowroomForm] = useState(false);

  const showrooms = ["Paris", "London", "Bayard"];
  const [showroom, setShowroom] = useState(showrooms[0]);

  const handleScanData = (data) => {
    if (!showScanner) return;
    setShowScanner(false);

    const handle = /products\/(.+)\?/.exec(data)[1];
    const colorName = /\?color\=(.+)/.exec(data)[1];
    axios
      .get(`/api/products/${handle}`)
      .then((res) => {
        const product = res.data;
        const color = product.colors.find(
          (c) => c.title.split(" ").join("") == colorName
        );
        console.log({ product });
        if (product && color) {
          setColor({
            color_id: color.id,
            product: product.title,
            handle: product.nona_id,
            color: color.title,
            showroom_code: product.showroom_code,
          });
          setOptions(true);
        }
      })
      .catch((err) => {
        window.alert("Product not found.");
      });
  };

  const handleConfirm = (available) => {
    setDisabled(true);
    axios
      .patch("/api/inventory", {
        color_id: color.color_id,
        showroom,
        available,
      })
      .then((res) => {
        console.log(res);
        setDisabled(false);
        // setOptions(false);
      })
      .catch((err) => {
        console.log(err);
        window.alert("Error");
      });
  };

  if (showScanner) {
    return (
      <div className="w-full max-w-md mx-auto flex flex-col justify-center space-y-8 py-8">
        <QrReader
          constraints={{
            facingMode: "environment",
          }}
          scanDelay={1000}
          onResult={(result, error) => {
            if (!!result) {
              handleScanData(result?.text);
            }

            if (!!error) {
              console.info(error);
            }
          }}
        />
        <button
          className="bg-indigo-400 rounded p-3 text-white font-bold"
          onClick={() => setShowScanner(false)}
        >
          Cancel
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-6 flex flex-col justify-center space-y-4 pb-48">
      <div className="fixed inset-x-4 mx-auto bottom-12 max-w-2xl flex flex-col space-y-4">
        {color ? (
          <div className="p-4 border rounded bg-white" key={color.handle}>
            <div className="font-bold text-2xl mb-2">
              {color.handle} - {color.product}
            </div>
            <div className="mb-2">
              {color.color} - {color.showroom_code}
            </div>
            <div className="italic text-xl">{color.composition}</div>
            {options && (
              <div className="flex space-x-4 mt-8">
                <button
                  className="w-full bg-emerald-600 rounded p-4 text-white font-bold disabled:opacity-50"
                  onClick={() => handleConfirm(true)}
                  disabled={disabled}
                >
                  Set available
                </button>
                <button
                  className="w-full bg-rose-500 rounded p-4 text-white font-bold disabled:opacity-70"
                  onClick={() => handleConfirm(false)}
                  disabled={disabled}
                >
                  Set missing
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="p-4 border rounded bg-white">No code scanned.</div>
        )}
        <div
          className="flex items-end justify-between p-4 border rounded bg-white"
          onClick={() => setShowroomForm(true)}
        >
          <div>
            <div className="text-sm text-neutral-500 pb-0.5">Showroom</div>
            {showroom}
          </div>
          <button className="p-2">
            <PencilIcon className="w-6 h-6" />
          </button>
        </div>
        <button
          className="bg-indigo-700 rounded p-4 text-white font-bold"
          onClick={() => setShowScanner(true)}
        >
          Scan QR code
        </button>
      </div>
      {showroomForm && (
        <div
          className="fixed inset-0 bg-white bg-opacity-90 flex items-center justify-center"
          onClick={() => setShowroomForm(false)}
        >
          <div
            className="max-w-xl w-full bg-white rounded-lg border shadow p-4"
            onClick={(evt) => evt.stopPropagation()}
          >
            <h2 className="text-3xl font-bold mb-8">Select showroom</h2>
            {showrooms.map((sh) => (
              <div
                key={sh}
                className={`flex items-center justify-between border mb-4 p-4 rounded-lg select-none cursor-pointer font-bold hover:bg-indigo-600 hover:text-white transition-colors ${
                  showroom == sh ? "bg-indigo-600 text-white" : "bg-white"
                }`}
                onClick={() => {
                  setShowroom(sh);
                  setShowroomForm(false);
                }}
              >
                <div>{sh}</div>
                {showroom == sh && (
                  <CheckCircleIcon className="w-6 h-6 text-white" />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
