import React, { useEffect, useState, useCallback } from "react";

import { Provider } from "@shopify/app-bridge-react";
import { AppProvider, Page, Layout, Card, Spinner } from "@shopify/polaris";

export default function OrderPage(props) {
  const { apiKey, host } = props;
  const config = { apiKey, host };

  const [order, setOrder] = useState({});
  const loadOrder = async () => {
    const res = await fetch(`/orders/${props.orderId}.json`);
    const order = await res.json();
    console.log(order);
    setOrder(order);
  };
  useEffect(() => {
    loadOrder();
    return () => {};
  }, order);

  if (order?.name) {
    return (
      <AppProvider>
        <Provider config={config}>
          <Page title={`Order ${order?.name}`}>
            <Layout>
              <Layout.Section>
                <Card></Card>
              </Layout.Section>
            </Layout>
          </Page>
        </Provider>
      </AppProvider>
    );
  } else {
    return (
      <AppProvider>
        <Provider config={config}>
          <Page title={`Loading order...`}>
            <Layout>
              <Layout.Section>
                <Spinner accessibilityLabel="Spinner example" size="large" />
              </Layout.Section>
            </Layout>
          </Page>
        </Provider>
      </AppProvider>
    );
  }
}