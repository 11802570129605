import React, { useState } from "react";
import { Button, Card, TextContainer } from "@shopify/polaris";
import axios from "axios";

import getHeaders from "../utils/getHeaders";

export default function BatchUpdates() {
  const [loading, setLoading] = useState(false);

  const postToUrl = (url) => {
    setLoading(true);
    axios
      .post(url, null, {
        headers: getHeaders(),
      })
      .then(() => setLoading(false));
  };

  return (
    <Card title="Batch Updates">
      <Card.Section>
        <TextContainer>
          Use these tools for mass updates of your product catalog.
        </TextContainer>
      </Card.Section>
      <Card.Section>
        <Button onClick={() => postToUrl("/variants")} loading={loading}>
          Add photos to variants
        </Button>
      </Card.Section>
      <Card.Section>
        <Button
          onClick={() => postToUrl("/inventory_update")}
          loading={loading}
        >
          Update inventory on Airtable
        </Button>
      </Card.Section>
      <Card.Section>
        <Button
          onClick={() => postToUrl("/all_showroom_slips")}
          loading={loading}
        >
          Generate all showroom slips
        </Button>
      </Card.Section>
      {/* <Card.Section>
        <Button onClick={() => postToUrl("/storefront")} loading={loading}>
          Make products available to Storefont API
        </Button>
      </Card.Section> */}
    </Card>
  );
}
