import React, { useEffect, useState, useCallback } from "react";

import { Provider } from "@shopify/app-bridge-react";
import {
  AppProvider,
  Page,
  Layout,
  Card,
  ResourceList,
  Filters,
  Pagination,
} from "@shopify/polaris";

import OrderItem from "./OrderItem";
import { debounce } from "lodash";

export default function OrdersPage(props) {
  const { apiKey, host } = props;
  const config = { apiKey, host };

  const [queryValue, setQueryValue] = useState("");

  const [edges, setEdges] = useState([]);

  const [selectedOrders, setSelectedOrders] = useState([]);

  const [page, setPage] = useState(1);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [query, setQuery] = useState(null);

  const updateData = (orders) => {
    setEdges(orders.edges);
    setHasNextPage(orders.pageInfo.hasNextPage);
    setHasPreviousPage(orders.pageInfo.hasPreviousPage);
  };

  const loadOrders = async () => {
    const url = queryValue.length
      ? `/orders.json?search=${queryValue}`
      : "/orders.json";
    const res = await fetch(url);
    const orders = await res.json();
    // console.log(orders);
    updateData(orders);
  };

  const handlePrevious = async () => {
    const cursor = edges[0].cursor;
    const res = await fetch(`/orders.json?before=${cursor}`);
    const orders = await res.json();
    setPage(page - 1);
    updateData(orders);
  };

  const handleNext = async () => {
    const cursor = edges[edges.length - 1].cursor;
    const res = await fetch(`/orders.json?after=${cursor}`);
    const orders = await res.json();
    setPage(page + 1);
    updateData(orders);
  };

  const debouncedLoadOrders = debounce(loadOrders, 500);

  useEffect(() => {
    loadOrders();
    return () => {};
  }, []);

  useEffect(debouncedLoadOrders, [queryValue]);

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
  }, []);

  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
  }, []);

  const filterControl = (
    <Filters
      disabled={!edges.length}
      queryValue={queryValue}
      onQueryChange={handleFiltersQueryChange}
      onQueryClear={handleQueryValueRemove}
      filters={[]}
    />
  );

  const orders = edges.map((edge) => edge.node);

  const filteredOrders = queryValue
    ? orders.filter(
        (order) =>
          order.name.includes(queryValue) ||
          order.customer.displayName
            .toLowerCase()
            .includes(queryValue.toLowerCase())
      )
    : orders;

  const promotedBulkActions = [
    {
      content: "Download invoices",
      onAction: () => {
        selectedOrders.forEach((id) => {
          const legacyId = id.match(/.+\/Order\/(\d+)/)[1];
          window.open(`/invoice?id=${legacyId}&dl=true`, "_blank");
        });
      },
    },
  ];

  return (
    <AppProvider>
      <Provider config={config}>
        <Page title="Orders" narrowWidth>
          <Layout>
            <Layout.Section>
              <Card>
                <ResourceList
                  items={orders}
                  selectedItems={selectedOrders}
                  onSelectionChange={setSelectedOrders}
                  renderItem={OrderItem}
                  filterControl={filterControl}
                  resourceName={{ singular: "order", plural: "orders" }}
                  promotedBulkActions={promotedBulkActions}
                  loading={orders.length <= 0}
                />
              </Card>
            </Layout.Section>
            <Layout.Section>
              <Pagination
                label={`Page ${page}`}
                hasPrevious={hasPreviousPage}
                onPrevious={handlePrevious}
                hasNext={hasNextPage}
                onNext={handleNext}
              />
            </Layout.Section>
          </Layout>
        </Page>
      </Provider>
    </AppProvider>
  );
}
